<template lang="pug">
form-layout(title="ᲨᲔᲡᲕᲚᲐ")
	template(#right)
		.cont
			DefaultInput.item(text="ტელეფონი" v-model:value="data.phone_number" :errors="errors.phone_number")
			DefaultInput.item(text="პაროლი" type="password" v-model:value="data.password" :errors="errors.password" @enterClick="login()")
			.item
				DefaultButton(text="ᲨᲔᲡᲕᲚᲐ" @clicked="login()")
				p.not-register
					span დარეგისტრირებული არ ხარ?
					router-link(:to="{name:'Registration'}") რეგისტრაცია
				p
					span პაროლი დაგავიწყდა?
					router-link(:to="{name:'RecoverPassword'}") პაროლის შეცვლა
</template>

<script>
import FormLayout from "../components/layout/FormLayout";
import DefaultInput from "../components/layout/DefaultInput";
import DefaultButton from "../components/layout/DefaultButton";

export default {
	name: "Login",
	components: {
		FormLayout,
		DefaultInput,
		DefaultButton
	},
	data() {
		return {
			errors: {},
			data: {
				phone_number: null,
				password: null
			}
		}
	},
	methods: {
		login() {
			this.$store.dispatch("user/login", this.data).then(() => {
				this.errors = {}
				this.$router.push({name: 'Home'})
			}).catch(err => {
				this.errors = err
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.cont {
	margin: 33px 0 0;

	.item {

		&:not(:first-child) {
			margin-top: 18px;
		}

		p {
			font-size: 12px;

			&.not-register {
				margin: 10px 0 5px;
			}

			span {
				cursor: default;
				color: #434242;
			}

			a {
				cursor: pointer;
				padding-left: 6px;
				color: black;
				font-weight: bold;
				text-decoration: none;
			}
		}
	}

}
</style>
